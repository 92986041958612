import React from "react";
import { Link } from "gatsby";
import HowToLayout from "../../../components/how-to-layout";
import pricingData from "../../../data/pricing-data-organizers.json";
import PriceTiers from "../../../components/priceTiers";
import { FaAndroid, FaApple } from "react-icons/fa";

const Pricing = () => {
  return (
    <HowToLayout>
      <h2>Pricing</h2>
      <section className="documentation-section">
        <p>
          Creating an account, using our built in scoreboard, and participating
          in leagues will ALWAYS be free with 4BAGR! Other than that, we like to
          keep things simple. Check out our current price tiers below:
        </p>
        <PriceTiers data={pricingData} />
      </section>
      <section className="documentation-section">
        <h3 id="how-to-purchase">How to Purchase</h3>
        <p>
          To purchase a league, simply open the 4BAGR mobile app, navigate to
          the menu, and select 'Create League'. Here you will find the pricing
          options and be able to pay and have access to the league creation form
          instantly. All payments are handled and processed by the Google Play
          Store for Android devices or the App Store for iOS devices.
        </p>
      </section>
      <section className="documentation-section">
        <h3 id="cancel-refund">Cancellations and Refunds</h3>
        <p>
          If you have any issues at all with your purchase or need to cancel
          your subscription, please first reach out to{" "}
          <a
            href="mailto:support@4bagr.app"
            className="inline-link"
            target="_blank"
          >
            Support@4BAGR.app
          </a>{" "}
          for immediate help. 4BAGR does not have total control over refunds or
          cancellations, as the app stores generally like to handle this, but we
          can help as much as possible.
        </p>
        <p>
          To directly cancel a subscription from either store, follow the
          respective links below:
          <ul style={{ listStyle: "none", margin: "15px 0" }}>
            <li>
              <a
                href="https://support.google.com/googleplay/answer/7018481?co=GENIE.Platform%3DAndroid&hl=en"
                className="inline-link"
                target="_blank"
              >
                <FaAndroid style={{ paddingTop: 5 }} /> Google Play (Android
                Cancellation)
              </a>
            </li>
            <li>
              <a
                href="https://support.apple.com/en-us/HT202039"
                className="inline-link"
                target="_blank"
              >
                <FaApple style={{ paddingTop: 3 }} /> App Store (Apple iOS
                Cancellation)
              </a>
            </li>
          </ul>
        </p>
      </section>
    </HowToLayout>
  );
};

export default Pricing;
