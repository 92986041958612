import React from "react";
import { FaCheckCircle, FaCircle, FaTimesCircle } from "react-icons/fa";

const PriceTiers = ({ data }) => {
  return (
    <div className="pricing-tiers-wrap">
      {data.map((tier, index) => {
        return (
          <div className={`pricing-tier pricing-tier-bg-${index + 1}`}>
            <div className="pricing-tier-top">
              <h2>{tier.title}</h2>
              {tier.price ? (
                <>
                  <h5>${tier.price}</h5>
                  <span>{tier.priceDescription}</span>
                </>
              ) : null}
              <p>{tier.description}</p>
            </div>
            <div class="pricing-tier-bottom">
              <ul>
                {tier.features.map((feature) => {
                  return (
                    <li>
                      {feature.included ? (
                        <FaCheckCircle className="price-icon" />
                      ) : (
                        <FaTimesCircle className="price-icon faded" />
                      )}
                      <span>{feature.feature}</span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PriceTiers;
